// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  background: color('header-green');
  padding:  0;
  position: relative;
  z-index: 10;
  box-shadow: 0px 0 5px 5px rgba(#000, 0.5);

  // region-header-image
  .region-header-image {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    a,
    img {
      display: none;
      padding: 0;
      margin: 0;
      width: auto;
      height: 115px;

      @media all and (min-width: $col-sm) and (max-width: $col-lg){
        display: block;
        height: 115px;
      }

      @media all and (min-width: $col-lg) {
        display: block;
        height: 215px;
      }
    }
  }

  @include respond-to(col-sm) {
    background: color('header-green');
  }

  @include respond-to(col-md) {
    padding: 20px 0;
  }

  // Wrapping link for logo.
  &__logo {
    margin: 0 auto;
    margin-bottom: 1.5em;
    padding: 0;
    display: block;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;

      &:hover,
      &:active {
        @extend .rubberBand;
      }
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }

  // main menu
  .block-menu {
    position: relative;
    z-index: 2;
    ul.menu {
      padding: 0;
      margin: 0;
      text-align: center;

      li {
        display: inline-block;

        a {
          @include typeface(cursive);
          text-transform: uppercase;
          font-weight: bold;
          color: color('white');
          padding: 0 10px;
          text-decoration: none;
          @include type-layout(l, 1.5);
          text-shadow: 2px 2px 2px rgba(#000, 0.8);

          @include respond-to(col-sm) {
            @include type-layout(xl, 2);
            padding: 0 15px;
          }

          @include respond-to(col-lg) {
            @include type-layout(nav-med, 2);
            padding: 0 15px;
          }

          &:hover,
          &:active,
          &.active {
            color: color('yellow');
          }

        }
      }
    }
  }

}

// titles
.interior-section {
  padding-top: 25px;
}
.region-hero {
  position: static;
  overflow: hidden;
  min-height: 130px;

  @include respond-to(col-md) {
    min-height: 200px;
  }
}

body.small-title {
  .page-title {
    h1 {
      @include respond-to(col-md) {
        font-size: 3rem;
      }
    }
  }
}
.page-title {
  background: color('blue') url('../img/ink-splat.png') top left no-repeat;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  overflow: hidden;

  // date
  .post-date {
    color: color('white');
    @include typeface(cursive);
    @include type-layout(l, 2);
    text-shadow: 2px 2px 2px rgba(#000, 0.8);

    @include respond-to(col-md) {
      @include type-layout(nav-med, 2);
      padding-left: 225px;
    }
  }

  // orange
  &.orange {
    background: color('orange') url('../img/ink-splat-orange.png') top left no-repeat;
  }

  h1 {
    color: color('white');
    font-weight: 100;
    text-transform: uppercase;
    @include typeface(cursive);
    text-shadow: 2px 2px 2px rgba(#000, 0.8);
    position: relative;

    @include respond-to(col-md) {
      padding-left: 225px;
      @include type-layout(title-large, 3.25);
    } 

    // icon
    .field-title-icon {
      position: absolute;
      top: -76px;
      left: 0;
      z-index: 1;
      opacity: 0.5;

      @include respond-to(col-md) {
        opacity: 1;
      }

    }
  }
}

// hero
.region-hero {
  height: 300px;
  @include respond-to(col-sm) {
    height: 300px;
  }
  @include respond-to(col-md) {
    height: 700px;
  }
}


/*----------------------------------------------------------------------------*\
  Headhesive Specific Styles
/*----------------------------------------------------------------------------*/
.node-type-homepage .header {
  &.banner--clone {
    padding: 0;
    @include respond-to(col-md) {
      padding: 20px 0;
    }
  }
}

.header{
  &.banner--clone {

    /* Required styles */
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;

    /* Additional styles */
    background: color('blue');
    box-shadow: 0px 0 5px 5px rgba(#000, 0.5);
    .header__logo {
      @include visually-hidden;
    }


    // region-header-image
    .region-header-image {
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all .2s ease-in;

      a,
      img {
        display: block;
        padding: 0;
        margin: 0;
      }

      a,
      img {
        display: none;
        @include respond-to(col-sm) {
          display: block;
          height: 75px;
          width: auto;
        }
      }
    }

    @include respond-to(col-sm) {
      background: color('blue');

      &:hover,
      &:active {
        background: color('orange');
        .region-header-image {
          position: absolute;
          bottom: 0;
          left: 30px;
          transition: all .2s ease-in;
        }
      }

    }

    /* Translate -100% to move off screen */
    -webkit-transform: translateY(-110%);
        -ms-transform: translateY(-110%);
            transform: translateY(-110%);

    /* Animations */
    -webkit-transition: all 300ms ease-in-out;
       -moz-transition: all 300ms ease-in-out;
            transition: all 300ms ease-in-out;

  }

  &.banner--stick {

    /* Translate back to 0%; */
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
  }


  &.banner--unstick {
    /* Not required to use, but could be useful to have */
  }
}


// social nav
#block-menu-menu-social-menu {
  padding-top: 10px;
  @include respond-to(col-md) {
    padding-top: 0px;
  }

  ul.menu li a {
    font-size: 0;
    
    &:before {
      font-size: 20px;
      line-height: 20px;
      padding-right: .25em;
    }

    @include respond-to(col-lg) {
      font-size: 1.7em;

      &:before {
        font-size: 1.7em;
      }
    }

  }
}

