// Pager
//
// Paged navigation is a list of page numbers when more than 1 page of content
// is available.
//
// Markup: pager.twig
//
// Style guide: navigation.pager

.pager,
%pager {
  clear: both;
  padding: 0;
  text-align: center;

  @media print {
    display: none;
  }

  // A page item in the pager list.
  &__item {
    display: inline;
    padding: 0 .5em;
    list-style-type: none;
    background-image: none;
  }

  // The current page's list item.
  &__current-item {
    @extend %pager__item;
    font-weight: bold;
  }
}

//
// Drupal selectors.
//

// .pager-item     - A list item containing a page number in the list of pages.
// .pager-first    - The first page's list item.
// .pager-previous - The previous page's list item.
// .pager-next     - The next page's list item.
// .pager-last     - The last page's list item.
// .pager-ellipsis - A concatenation of several list items using an ellipsis.

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  @extend %pager__item;
  @extend %button;
  -moz-appearance: none;
  -webkit-appearance: none;
  line-height: 100px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 2px rgba(#000,0.5);
  transform: rotate(-15deg) scale(1,1);
  display: inline-block;

  a {
    color: color('white');
    text-decoration: none;

    &:link,
    &:visited {
      text-decoration: none;
    }
  }

  &:hover,
  &:active,
  &.active {
    transform: rotate(-375deg) scale(1.25,1.25);
     z-index: 2;
  }

}

.pager-current {
  @extend %pager__current-item;
  @extend %button;
  -moz-appearance: none;
  -webkit-appearance: none;
  line-height: 100px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 2px rgba(#000,0.5);
  transform: rotate(-15deg) scale(1,1);
  display: inline-block;
  background: color('orange');
}

.pager-first,
.pager-previous,
.pager-next,
.pager-last {
  width: auto;
}
