.instagram-feed {
    text-align: center;
    padding: 1rem 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#438cca+0,58c2d8+100 */
    background: rgb(67,140,202); /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, rgba(67,140,202,1) 0%, rgba(88,194,216,1) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(67,140,202,1) 0%,rgba(88,194,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(67,140,202,1) 0%,rgba(88,194,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#438cca', endColorstr='#58c2d8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.instagram-feed .instagram-feed__headline,
.instagram-feed .instagram-feed__link {
    @include typeface(cursive);
    text-transform: uppercase;
    font-weight: bold;
    color: color('white');
    text-shadow: 2px 2px 2px rgba(#000, 0.8);
}
.instagram-feed .instagram-feed__headline {
    @include type-layout(title-large, 5);
}
.instagram-feed .instagram-feed__link {
    @include type-layout(xxl, 3);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: color('yellow');
    }
}
.instagram-feed .instagram-feed__items {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}
.instagram-feed .instagram-feed__items a {
    display: block;
    margin: 2rem;
    border: 1rem solid #fff;
    box-shadow: 2px 2px 2px rgba(#000, 0.5);
    transition: all 0.25s ease-in-out;
     
    &:hover,
    &:hover,
    &:hover {
        transition: all 0.25s ease-in-out;
    }
}

.instagram-feed .instagram-feed__items a:nth-child(odd) {
    transform: rotate(-5deg);

    &:hover,
    &:hover,
    &:hover {
        transform: rotate(5deg);
    }
}
.instagram-feed .instagram-feed__items a:nth-child(even) {
    transform: rotate(5deg);

    &:hover,
    &:hover,
    &:hover {
        transform: rotate(-5deg);
    }
}
.instagram-feed .instagram-feed__items a img {
    display: block;
    padding: 0;
    margin: 0;
}