.paragraphs-item-jump-links,
.paragraphs-item-gallery {
  @extend .row;
  margin-top: 25px;
  margin-bottom: 25px;
}
.field-jump-links,
.field-gallery-images {
  margin-bottom: 25px;

  // gallery
  a {
    border-bottom: 10px solid color('white');
  }

  // tilt
  &:nth-child(odd) {
    a {
      transform: rotate(-2deg);
      transition: all .2s ease-in;

      &:hover,
      &:active {
        transform: rotate(-360deg);
        transition: all .2s ease-in;
        z-index: 4;
      }
    }
  }
  &:nth-child(even) {
    a {
      transform: rotate(2deg);
      transition: all .2s ease-in;

      &:hover,
      &:active {
        transform: rotate(360deg);
        transition: all .2s ease-in;
        z-index: 4;
      }
    }
  }

  // polaroid effect
  a {
    display: block;
    border-top: 10px solid color('white');
    border-right: 10px solid color('white');
    border-left: 10px solid color('white');
    background: color('white');
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    @include typeface(cursive);
    @include type-layout(xxl, 1.2);
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 5px 5px rgba(#000, 0.15);

    // corner effect
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top: 20px solid color('white');
      border-right: 20px solid color('white');
      border-bottom: 20px solid transparent;
      border-left: 20px solid transparent;
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: 2;
    }

    // title
    .field-jump-link-url {
      padding: 10px;
    }

  }
}
