.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(black, 0.8);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.modal--hidden {
    display: none !important;
}
.modal__container {
    position: relative;
    width: 80%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#438cca+0,58c2d8+100 */
    background: color('blue'); /* Old browsers */
    background: -moz-linear-gradient(left, color('blue') 0%, color('light-blue') 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, color('blue') 0%,color('light-blue') 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, color('blue') 0%,color('light-blue') 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#438cca', endColorstr='#58c2d8',GradientType=1 ); /* IE6-9 */
    

    @media all and (min-width: $col-sm) {
        transform: skew(25deg);
    }
 }
.modal__content {
    padding: 0 2rem;
    text-align: center;

    @media all and (min-width: $col-sm) {
        transform: skew(-25deg);
        padding: 3rem 5rem;
    }

    h1,h2,h3,h4,h5,h6 {
        @include typeface(cursive);
        text-transform: uppercase;
        font-weight: bold;
        color: color('white');
    }

    h2 {
        font-size: 2rem;
        text-shadow: 2px 2px 2px rgba(#000, 0.8);
    }

    a {
        @include typeface(cursive);
        text-transform: uppercase;
        border: 1px solid color('white');
        padding: 1rem;
        display: inline-block;
        text-decoration: none;
        background: color('orange');
        color: color('white');
        font-size: 1.5rem;
        text-shadow: 2px 2px 2px rgba(#000, 0.8);
        box-shadow: 2px 2px 2px rgba(#000, 0.8);

        &:hover,
        &:active,
        &:focus {
            background: color('salmon');
        }
    }
}
.modal__close {
    position: absolute;
    z-index: 2;
    right: 1rem;
    top: 1rem;
    // height: 1rem;
    // width: 1rem;
    @include typeface(cursive);
    text-transform: uppercase;
    color: color('white');
    font-size: 1.5rem;
    background: rgba(black, 0.8);
    padding: 0.5rem;
    border-radius: 100%;

    &:hover,
    &:active {
        cursor: pointer;
        color: color('black');
        font-size: 1.5rem;
        background: rgba(white, 0.8);  
    }

    @media all and (min-width: $col-sm) {
        transform: skew(-25deg);
        right: 3rem;
    }
}