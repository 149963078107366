.interior-section {
  position: relative;
  z-index: 1;

  .region-section-1-content,
  .region-section-2-content {
    padding-top: 50px;
    @include respond-to(col-sm) {
      padding-bottom: 200px;
      margin-bottom: 300px;
    }

    // overflow issue
    .row {
      margin-left: 0;
      margin-right: 0;
    }

  }

  // paragraphs-item-circle-images
  .paragraphs-item-circle-images {
    @include respond-to(col-sm) {
      max-width: 1165px;
      position: absolute;
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 50%);
      width: 100%;
      z-index: 2;
    }
  }
}
