.paragraphs-item-circle-images {
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 100%;
    border: 10px solid color('white');
    box-shadow: 0 0 5px 5px rgba(#000, 0.25);
    margin-bottom: 20px;
  }
}
