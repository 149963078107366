.field-cursive-text {
  @include typeface(cursive);
  color: color('white');
  @include type-layout(nav-large, 2);
  @include margin-top(1, nav-large);
  @include margin-bottom(1, nav-large);
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(#000, 0.8);
}
