#popup-announcement-wrap {
  overflow: auto;
  background: color("orange");

  @media all and (max-width: 768px) {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }

  // close
  #popup-announcement-close {
    top: 0;
    right: 0;
    background: none;
    z-index: 2;

    &:hover,
    &:active {
      &:before {
        color: color("grey");
      }
    }

    &:before {
      content: "\0058";
      display: block;
      height: 26px;
      width: 26px;
      line-height: 26px;
      font-size: 26px;
      color: color("white");
      z-index: 3;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
    }
  }

  // content
  #popup-announcement {
    color: color("white");
    padding: 0;

    // headlines
    h1,
    h2,
    h3,
    h4,
    h5,
    h5 {
      @include typeface(cursive);
      text-shadow: 2px 2px 2px rgba(#000, 0.8);
    }

    > h1,
    h2,
    h3,
    h4,
    h5,
    h5 {
      margin-top: 0;
    }

    // buttons
    a.btn {
      display: block;
      text-align: center;
      border-radius: 10px;
      @include typeface(cursive);
      color: color("white");
      text-decoration: none;
      padding: 5px 10px;
      font-size: 2em;
      background: color("light-blue");
      box-shadow: 2px 2px 2px rgba(#000, 0.8);

      &:hover,
      &:active {
        background: darken(color("light-blue"), 15%);
      }
    }
  }
  a,
  a:link,
  a:visited {
    color: #4d3857;
  }
}

#cboxTitle {
  background: rgba(255, 255, 255, 0.9);
  color: #000000;
}
