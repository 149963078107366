#footer {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/color('light-green')+0,1f997a+100 */
  background: color('light-green'); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, color('light-green') 0%, color('dark-green') 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, color('light-green') 0%,color('dark-green') 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, color('light-green') 0%,color('dark-green') 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#37b279', endColorstr='#1f997a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  // layout
  padding-top: 50px;
  padding-bottom: 50px;

  // sidebar
  .panels-flexible-region-row-sidebar {
    .panel-pane {
      text-align: center;
      @include respond-to(col-sm) {
        text-align: center;
        float: left;
      }
    }
  }

  .panels-flexible-region-row-center{
    .panel-pane {
      text-align: center;
      @include respond-to(col-sm) {
        text-align: center;
        float: right;
      }
    }
  }

  // footer
  .panels-flexible-row-last {
    text-align: center;
  }

  // images
  img {
    display: block;
    margin: 0 auto;
    @include respond-to(col-sm) {
      display: inline;
      margin: auto;
    }
  }

  // text
  color: color('white');
  text-transform: uppercase;

  a {
    color: color('white');

    &:link,
    &:visited {
      text-decoration: underline;
      color: color('white');
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  // back to top
  a.back-to-top {
    position: relative;
    padding-left: 128px;
    height: 108px;
    display: block;
    line-height: 108px;
    font-size: 1.5em;
    margin: 0 auto;
    margin-bottom: 150px;
    text-align: center;

    @include respond-to(col-sm) {
      margin-bottom: 150px;
      text-align: left;
    }

    &:link,
    &:visited {
      text-decoration: none;
      color: color('white');
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: color('white');

      // arrow
      &:before {
        transform: rotate(360deg);
        transition: all .2s ease-in;
      }

    }

    // arrow
    &:before {
      content: "";
      display: block;
      height: 108px;
      width: 108px;
      position: absolute;
      top: 0;
      left: 0;
      background: url('../img/back-to-top.png') center center no-repeat;
      transform: rotate(0deg);
      transition: all .2s ease-in;
    }
  }

  // legals
  .legals {
    @include type-layout(xs, 1);
    p {
      text-align: center;
      // @include respond-to(col-sm) {
      //   float: left;
      //   margin: 0;
      //   text-align: left;
      // }
    }
    ul {
      text-align: center;
      list-style-type: none;
      padding: 0;
      // @include respond-to(col-sm) {
      //   text-align: left;
      // }

      li {
        display: inline;

        // first link
        &:first-child {
          a {
            padding-left: 0;
            @include respond-to(col-sm) {
              padding-left: 20px;
            }
          }
        }
        // last link
        &:last-child {
          a {
            padding-right: 0;
            border-right: 0;
          }
        }

        a {
          padding-right: 10px;
          padding-left: 10px;
          border-right: 1px solid color('white');
        }
      }
    }
  }

}


// target modern browsers
// html.cssclippathsvg {
//   #anchor {
//     padding-bottom: 150px;
//   }
//   #footer {
//     // clip-path: url('#footer-path');
//     // margin-top: -120px;
//     z-index: 5;
//     position: relative;
//     padding-top: 100px;
//     padding-bottom: 100px;
//   }
// }
