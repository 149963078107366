.paragraphs-item-circle-button {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  @include respond-to(col-sm) {
    text-align: left;
  }

  a {
    @include circle-link( color('orange'), color('white'), 2px, 200px );
  }
}
