// delays
@mixin delay($time) {
  -webkit-animation-delay: $time;
  animation-delay: $time;
}

// circle-link
@mixin circle-link($color, $text-color, $border-size, $size) {
  display: inline-block;
  background-color: $color;
  width: $size;
  height: $size;
  line-height: $size;
  @include typeface(cursive);
  color: $text-color;
  text-decoration: none;
  border: $border-size solid color('white');
  font-size: 5em;
  line-height: 70px;
  padding-top: 30px;
  text-align: center;
  border-radius: $size;
  transform: rotate(-375deg);
  transition: all .2s ease-in;

  &:link,
  &:visited {
    color: $text-color;
    text-decoration: none;
  }

  &:hover,
  &:active {
    background-color: darken($color, 10%);
    transform: rotate(-15deg);
    transition: all .2s ease-in;
  }
}
