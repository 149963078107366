/*** title ***/

// Ensure title is skewed
@-webkit-keyframes bounceInDownTitle {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, -3000px, 0) skew(5deg) rotate(-3deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, 25px, 0) skew(5deg) rotate(-3deg);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, -10px, 0) skew(5deg) rotate(-3deg);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, 5px, 0) skew(5deg) rotate(-3deg);
  }

  to {
    transform: skew(5deg) rotate(-3deg);
  }
}

@keyframes bounceInDownTitle {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, -3000px, 0) skew(5deg) rotate(-3deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, 25px, 0) skew(5deg) rotate(-3deg);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, -10px, 0) skew(5deg) rotate(-3deg);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) skew(5deg) rotate(-3deg);
    transform: translate3d(0, 5px, 0) skew(5deg) rotate(-3deg);
  }

  to {
    transform: skew(5deg) rotate(-3deg);
  }
}

.bounceInDownTitle {
  -webkit-animation-name: bounceInDownTitle;
  animation-name: bounceInDownTitle;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  @include delay(.5s);
}
