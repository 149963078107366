// back__button__fun
.back__button__fun {
  a {
    display: inline-block;
    @include typeface(cursive);
    display: inline-block;
    color: color('white');
    transform: skew(5deg) rotate(5deg);
    @include type-layout(nav-large, 2);
    background: color('blue');
    text-decoration: none;
    border: 2px solid color('white');
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    overflow: auto;
    padding: 3px 5px;
    transition: all .25s ease-in;
    overflow: hidden;

    &:hover,
    &:active {
      transition: all .25s ease-in;
      transform: skew(5deg) rotate(365deg);
    }
  }
}

// color pallet
.color_choice {
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 2px rgba(#000,0.5);
  outline: none;
  &:hover,
  &:active,
  &.activecolor {
    transform: scale(1.75,1.75);
     z-index: 2;
  }
}

// legals
.node-type-fun-stuff {
  .legals.printme {
    display: none;
  }
}

// canvas

// show scroll text on mobile
.scroll-bg {
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1366px)  {
    padding-right: 100px;
    padding-left: 100px;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      background: url('../img/scroll.png') center center repeat-y;
      width: 100px;
      height: 100%;
      display: block;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}

.scroll-bg {

  @media all and (max-width: $col-md) {
    padding-right: 100px;
    padding-left: 100px;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      background: url('../img/scroll.png') center center repeat-y;
      width: 100px;
      height: 100%;
      display: block;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

  }

}

#simple_sketch {
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1366px)  {
    width: 100%;
  }
  @media all and (max-width: $col-md) {
    width: 100%;
  }
}

// keep canvas ratio
.intrinsic-ratio-landscape {
  height:186px;

  @include respond-to(col-xs) {
    height:294px;
  }

  @include respond-to(col-sm) {
    height:485px;
  }

  @include respond-to(col-md) {
    height:645px;
  }

  @include respond-to(col-lg) {
    height:760px;
  }
}


.intrinsic-ratio-portait {
  height:508px;

  @include respond-to(col-sm) {
    height:556px;
  }

  @include respond-to(col-md) {
    height:988px;
  }

  @include respond-to(col-lg) {
    height:1200px;
  }

}

.canvastools {
  ul {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 25px 0;
    list-style-type: none;

    .button {
      -moz-appearance: none;
      -webkit-appearance: none;
      line-height: 100px;
    }

    li {
      display: inline-block;
      vertical-align:top;
    }

    &.color,
    &.size {
      .button {
        width: 50px;
        height: 50px;
        line-height: 50px;
      }
    }

    // active
    a {
      position: relative;
      z-index: 1;
      box-shadow: 0px 0px 2px rgba(#000,0.5);
      transform: rotate(-15deg) scale(1,1);
      &:hover,
      &:active,
      &.active {
        transform: rotate(-375deg) scale(1.25,1.25);
         z-index: 2;
      }
    }

  }
}

// word search

/*** canvas ***/

.paragraphs-item-word-search {

  .canvastools {
    display: none;

    @media only screen
    and (min-device-width : 1024px)
    and (max-device-width : 1366px) {
      display: block;
    }

    @media all and (max-width: $col-sm) {
      display: block;
    }

  }

  .print-instructions {
    @media only screen
    and (min-device-width : 1024px)
    and (max-device-width : 1366px) {
      display: none;
    }

    @media all and (max-width: $col-sm) {
      display: none;
    }
  }

  #simple_sketch {
    display: none;

    @media only screen
    and (min-device-width : 1024px)
    and (max-device-width : 1366px) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
    }

    @media all and (max-width: $col-sm) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
  }

  .intrinsic-ratio-square {
    @media only screen
    and (min-device-width : 1024px)
    and (max-device-width : 1366px) {
      width: 760px;
      height: 760px;
      position: relative;
      z-index: 1;
    }

    @media all and (max-width: $col-sm) {
      width: 312px;
      height: 312px;
      position: relative;
      z-index: 1;
    }
  }
}

.print-instructions {
  display: none;

  @include respond-to(col-sm) {
    display: block;
  }
}

/**
* Styles for the puzzle
*/
#puzzle {
  border: 1px solid black;
  padding: 20px;
  background: color('white');
  float: left;

  @media only screen
  and (min-device-width : 1024px)
  and (max-device-width : 1366px) {
    width: 760px;
    height: 760px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media all and (max-width: $col-sm) {
    width: 312px;
    height: 312px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

}
#puzzle div {
  width: 100%;
  margin: 0 auto;
}

/* style for each square in the puzzle */
#puzzle .puzzleSquare {
  height: 25px;
  width: 25px;
  text-transform: uppercase;
  background-color: white;
  border: 0;
  font: 1em sans-serif;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  background-color: none;
  @include typeface(body);
  color: color('text');
  text-align: center;
  transform: none;
  @include type-layout(m, 1);
  padding: 0;
  text-decoration: none;
  border-width: none;
  border-radius: 0px;
  transition: none;

  @include respond-to(col-md) {
    height: 60px;
    width: 60px;
    @include type-layout(xl, 1);;
  }

  @include respond-to(col-lg) {
    height: 70px;
    width: 70px;
    @include type-layout(xl, 1);;
  }

}

button::-moz-focus-inner {
  border: 0;
}

/* indicates when a square has been selected */
#puzzle .selected {
  background-color: color('orange');
}

/* indicates that the square is part of a word that has been found */
#puzzle .found {
  background-color: color('blue');
  color: color('white');
}

#puzzle .solved {
  background-color: color('violet');
  color: color('white');;
}

/* indicates that all words have been found */
#puzzle .complete {
  background-color: color('dark-green');
}

/**
* Styles for the word list
*/
#words {
  padding-top: 20px;
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
  width: auto;
  clear: both;
  @include respond-to(col-sm) {
    clear: none;
  }
}

#words ul {
  list-style-type: none;
  margin: 0;
}

#words li {
  padding: 3px 0;
  @include typeface(cursive);
  @include type-layout(xl, 1.5);

  @include respond-to(col-md) {
    @include type-layout(xxl, 1.5);
  }

  @include respond-to(col-lg) {
    @include type-layout(nav-large, 2);
  }
}

/* indicates that the word has been found */
#words .wordFound {
  text-decoration: line-through;
  color: gray;
}

/**
* Styles for the button
*/

.word-search-buttons {
  clear: both;
  ul {
    padding: 0;
    margin: 25px 0 0 0;

    li {
      display: inline-block;
      vertical-align: top;

      button {
        line-height: 30px;
      }
    }
  }
}

// print
.node-type-fun-stuff {
  @media print {

    // hide everything
    visibility: hidden;
    background: none;
    position: relative;

    #footer,
    .layout-swap__top,
    .header,
    .hideme,
    .hero {
      display: none !important;
    }
    #anchor {
      padding-bottom: 0 !important;
    }

    * {
      visibility: hidden;
      position: static !important;
    }

    // show artwork and legals
    .printme, .printme * {
        visibility: visible;
    }
    // artwork
    .artwork.printme {
      position: absolute !important;
      top: 100px;
      left: 0;
      width: 100%;
    }
    // legals
    .legals.printme {
      display: block !important;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
    }
    .intrinsic-ratio-portait {
    	position: static;
    	padding-bottom:0;
    	height: auto;
    }
    #simple_sketch {
      -webkit-print-color-adjust: exact;
      position: static;
    	width: auto;
    	height: auto;
    }

  }
}
