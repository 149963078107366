.field-tilted-text {
  @include typeface(cursive);
  display: inline-block;
  color: color('white');
  transform: rotate(-15deg);
  @include type-layout(tilted-large, 5);
  @include margin-top(1, tilted-large);
  @include margin-bottom(1, tilted-large);
  font-weight: bold;
}
