// 100% height
.front {
  #fullpage,
  #anchor,
  .section
  #footer {
    height: 100vh;
  }
}


// global
.node-type-homepage {

  // header
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    background: none;
    box-shadow: none;

    // header imgage
    .region-header-image {
      display: none;
    }

    &.banner--clone {
      .region-header-image {
        display: block;
      }
    }
  }

  // menu
  .block-menu {
    margin-bottom: 0;
    ul.menu {
      padding: 0;
      margin: 0;
      text-align: center;

      li {
        display: inline-block;

        a {
          @include typeface(cursive);
          text-transform: uppercase;
          font-weight: bold;
          color: color('white');
          padding: 0 10px;
          text-decoration: none;
          @include type-layout(xl, 2);
          text-shadow: 2px 2px 2px rgba(#000, 0.8);

          @include respond-to(col-lg) {
            @include type-layout(nav-med, 2);
            padding: 0 20px;
          }

          &:hover,
          &:active {
            text-decoration: none;
          }

        }
      }
    }
  }

  // sections
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    // height
    height: auto;
    // images
    img {
      display: block;
      padding: 0;
      margin: 0;
    }
    // text
    color: color('white');
    text-shadow: 2px 2px 2px rgba(#000, 0.8);
    text-transform: uppercase;
    p {
      @include respond-to(col-sm) {
        @include type-layout(xl, 1.75);
      }
    }

  }

  // section 1
  .section-1 {
    padding-top: 215px;
    height: auto;
    min-height: auto;

    @include respond-to(col-sm) {
      min-height: 790px;
      position: relative;
    }
    @include respond-to(col-md) {
      height: 100%;
      min-height: 880px;
      position: relative;
    }
    @include respond-to(col-lg) {
      height: 100%;
      min-height: 1100px;
      position: relative;
    }

    // slogan
    .slogan-wrapper {
      @include typeface(cursive);
      background: color('light-blue');
      text-align: center;
      @include type-layout(xxl, 1.5);
      padding-top: 25px;
      padding-bottom: 100px;
      position: relative;
      bottom: -25px;
      z-index: 2;

      @include respond-to(col-sm) {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 30px;
        height: 150px;
        padding-top: 25px;
        @include type-layout(nav-med, 1.5);
      }

    }

    // flush with bottom
    .characters {
      @include respond-to(col-sm) {
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 24%;
      }
      @include respond-to(col-md) {
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 24%;
      }
      @include respond-to(col-lg) {
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 18%;
      }

      // make them overlap

      // characters
      .col-sm-pull-6 {
        position: relative;
        z-index: 2;
        img {
          @include respond-to(col-sm) {
            transform: translateX(10%);
          }
          @include respond-to(col-lg) {
            transform: translateX(15%);
          }
        }
      }
      // logo type
      .col-sm-push-6 {
        position: relative;
        z-index: 1;
        img {
          @include respond-to(col-sm) {
            // transform: scale(1.1,1.1);
            transform: translateX(-15%) scale(1.1,1.1);
          }
          @include respond-to(col-md) {
            // transform: scale(1.2,1.2);
            transform: translateX(-15%) scale(1.2,1.2);
          }
          @include respond-to(col-lg) {
            // transform: scale(1.4,1.4);
            transform: translateX(-15%) scale(1.2,1.2);
          }
        }
      }

    }
  }

  // section-3
  .section-3 {
    // align button to right
    .paragraphs-item-circle-button {
      @include respond-to(col-sm) {
        text-align: right;
      }
      // adjusut rotation
      a {
        @include circle-link( color('teal'), color('white'), 2px, 200px );
        transform: rotate(375deg);

        &:hover,
        &:active {
          transform: rotate(15deg);;
        }
      }
    }
  }


  // section-4
  .section-4 {
    // button
    .paragraphs-item-circle-button {
      a {
        @include circle-link( color('salmon'), color('white'), 2px, 200px );
      }
    }
  }

}

// animations
html.js {
  .node-type-homepage {
    // section 1
    .region-section-1-content {
      @include delay(.75s);
    }
  }
}

// target modern browsers
html.cssclippathsvg {
  .node-type-homepage {
    // section 1
    .section-1 {
      // height
      @include respond-to(col-lg) {
        height: 125%;
      }
      // flush with bottom
      // .characters {
      //   @include respond-to(col-sm) {
      //     width: 100%;
      //     max-width: 100%;
      //     position: absolute;
      //     bottom: 12%;
      //   }
      //   @include respond-to(col-lg) {
      //     width: 100%;
      //     max-width: 100%;
      //     position: absolute;
      //     bottom: 7%;
      //   }
      // }
    }
    // section 2
    .section-2 {
      // clip-path: url('#section-2-path');
      margin-top: -100px;
      z-index: 2;
      position: relative;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    // section 3
    .section-3 {
      // clip-path: url('#section-3-path');
      margin-top: -100px;
      z-index: 3;
      position: relative;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    // section 4
    .section-4 {
      // -webkit-clip-path: polygon(0 100%, 0 0, 67% 7%, 100% 0, 100% 100%);
      // clip-path: polygon(0 100%, 0 0, 67% 7%, 100% 0, 100% 100%);
      margin-top: -120px;
      z-index: 4;
      position: relative;
      padding-top: 100px;
      padding-bottom: 150px;

      // fix gap in sidebar
      .layout-center {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
