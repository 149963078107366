//
//
// Title
.node-type-article {

  .hero {
    .page-title {

      h1 {
        line-height: 1em;
        @include respond-to(col-md) {
          line-height: .85em;
        }
      }

      background: color('blue') url('../img/ink-splat.png') bottom left no-repeat;

      // orange
      &.orange {
        background: color('orange') url('../img/ink-splat-orange.png') bottom left no-repeat;
      }

      // date
      .post-date {
        color: color('white');
        @include typeface(cursive);
        @include type-layout(l, 2);
        text-shadow: 2px 2px 2px rgba(#000, 0.8);

        @include respond-to(col-md) {
          @include type-layout(nav-med, 2);
          padding-left: 225px;
        }
      }

      // icon
      .field-title-icon {
        top: -30px;
      }
    }
  }

}


// Archive and Tag Blocks
.pane-views.pane-blog,
#block-views-blog-block {

  // body
  background: color('orange');
  padding: 0 10px;
  overflow: auto;

  a {
    color: color('white');
    text-decoration: none;

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:active,
    &:focus  {
      color: color('white');
      text-decoration: underline;
    }
  }

  // archive list
  .item-list {
    text-align: center;

    ul {
      list-style-type: none;
    }

  }

  // title
  .pane-title,
  .block__title {
    color: color('white');
    @include typeface(cursive);
    text-shadow: 2px 2px 2px rgba(#000, 0.8);
    @include type-layout(nav-med, 2);
    text-align: center;
  }

}

.pane-node-field-tags {
  background: none;
  margin: 10px 0;
  .field-tags {
    display: inline-block;
    a {
      display: block;
      background: color('salmon');
      padding: 5px;
      text-decoration: none;
      border: 2px solid color('white');
      color: color('white');

      &:link,
      &:visited {
        text-decoration: none;
        color: color('white');
      }

      &:hover,
      &:active {
        background: darken(color('salmon'), 25%);
        color: color('white');
      }
    }
  }
}

// Teasers

// individual teaser
.views-row {
  .node-teaser.node-article,
  .node-teaser.node-event {
    padding-bottom: 20px;
    padding-top: 24px;
    border-bottom: 1px solid color('black');
  }
}

// read more
.node-teaser.node-article,
.node-teaser.node-event {
  .node-readmore {
    a {
      display: block;
      text-align: center;
      border: 3px solid color('white');
      color: color('white');
      text-decoration: none;
      background: color('teal');
      @include typeface(cursive);
      font-size: 2em;
      padding: 10px;

      &:hover,
      &:active {
        background-color: darken(color('teal'), 10%);
      }

    }
  }
}


// title, date and hero image
.blog-teaser-wrapper {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  color: color('white');
  text-decoration: none;
  margin-bottom: 20px;
  overflow: hidden;

  // hover states
  &:active,
  &:focus,
  &:hover {
    color: color('white');

    .metadata {
      transition: all .25s ease-in;
      background: color('salmon');
      @include respond-to(col-md) {
        background: color('salmon');
        background: rgba( color('salmon'), 0.5 );
      }
    }

    img {
      @include respond-to(col-md) {
        transition: all .25s ease-in;
        transform: scale(2,2);
      }
    }

  }

  // img
  img {
    display: block;
    padding: 0;
    margin: 0;
    @include respond-to(col-md) {
      transition: all .25s ease-in;
      transform: scale(1,1);
    }
  }

  // title and post date
  .metadata {
    overflow: auto;
    padding: 0 20px;
    text-align: center;
    background: color('blue');
    transition: all .25s ease-in;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    // position title and date over hero on large screens
    @include respond-to(col-md) {
      background: color('blue');
      background: rgba( color('blue'), 0.5 );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    // title
    h2 {
      @include typeface(cursive);
      text-shadow: 2px 2px 2px rgba(#000, 0.8);
      @include type-layout(nav-large, 2);
    }

    // date
    h6 {
      text-shadow: 2px 2px 2px rgba(#000, 0.8);
      @include typeface(cursive);
      @include type-layout(xl, 1.5);
    }

  }
}
